<template>
  <div @mouseleave="clearActiveSegment">
    <vue-table
        small-padding
        head-background
        fixed-bar
        fixed-head
        :offset-top="56"
        :hoverable-rows="false"
    >
      <template #head>
        <vue-line>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            Название сегмента
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            Уровень вложенности
          </vue-item>
          <vue-item
              colspan="2"
              valign="bottom"
          >
            {{ metricName }}
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            <v-table-head-cell
                order-by="diff"
                center
                sort
                sort-column
            >
              Дельта
            </v-table-head-cell>
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            <v-table-head-cell
                order-by="diff_prc"
                center
                sort
                sort-column
            >
              %
            </v-table-head-cell>
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            <v-table-head-cell
                order-by="diff_cg"
                center
                sort
                sort-column
            >
             Дельта от контрольной группы
            </v-table-head-cell>
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
          >
            <v-table-head-cell
                order-by="diff_prc_cg"
                center
                sort
                sort-column
            >
              % от контрольной группы
            </v-table-head-cell>
          </vue-item>
          <vue-item
              rowspan="2"
              valign="bottom"
              align="center"
          >
            Действия
          </vue-item>
        </vue-line>

        <vue-line>
          <vue-item class="table-cell__period">
            <v-table-head-cell
                order-by="one"
                center
                sort
                sort-column
            >
              {{ dateFiltersFormatted.one.from}} -<br>
              {{ dateFiltersFormatted.one.to }}
            </v-table-head-cell>
          </vue-item>
          <vue-item class="table-cell__period">
            <v-table-head-cell
                order-by="two"
                center
                sort
                sort-column
            >
              {{ dateFiltersFormatted.two.from}} -<br>
              {{ dateFiltersFormatted.two.to }}
            </v-table-head-cell>
          </vue-item>
        </vue-line>
      </template>
      <template #body>
        <template v-if="items.length">
          <template v-for="item of items">
            <v-comparison-table-row
                v-bind="{
                  key: JSON.stringify(item),
                  item,
                  comparisonPeriod: dateFilters.comparison,
                  activeMetric,
                  level: getLevel(item.catalog_id),
                  activeSegment,
                  segmentIsControlGroup: controlGroup && item.catalog_id === controlGroup.catalog_id,
                  urlFilters,
                }"
            />
          </template>
          <v-comparison-table-row
              v-if="controlGroup"
              v-bind="{
                key: `${JSON.stringify(controlGroup)}cg`,
                item: controlGroup,
                comparisonPeriod: dateFilters.comparison,
                activeMetric,
                controlGroup: true,
                level: getLevel(controlGroup.catalog_id),
              }"
          />
        </template>
        <vue-line v-else>
          <vue-item colspan="9">
            <v-no-data/>
          </vue-item>
        </vue-line>
      </template>
    </vue-table>
  </div>
</template>

<script>
import {findOptions, hmsToSeconds, roundNumber, secondsToHms} from '@/function'
import { DATE_FORMAT } from '@/const'

import tableRow from '../comparisonTable/comparisonTableRow'
import noData from '@/services/errors/noData'
import tableHeadCell from '@/components/tableHeadCell/tableHeadCell'

import updateUrl from '@/mixins/updateUrl'

export default {
  mixins: [updateUrl],

  components: {
    'v-comparison-table-row': tableRow,
    'v-no-data': noData,
    'v-table-head-cell': tableHeadCell
  },

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    dateFilters: {
      type: Object,
      default: () => ({})
    },

    activeMetric: {
      type: Object,
      default: () => ({})
    },

    urlFilters: {
      type: Object,
      default: () => ({})
    },

    controlGroup: {
      type: Object,
      default: undefined
    },

    segmentTreeCatalog: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      activeSegment: undefined,
    }
  },

  computed: {
    metricName () {
      try {
        return this.activeMetric.name.replace('<br>', '')
      } catch (_) {
        return ''
      }
    },

    dateFiltersFormatted() {
      const {one, two} = this.dateFilters

      return {
        one: {
          from: this.$moment(one.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(one.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        },
        two: {
          from: this.$moment(two.from, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE),
          to: this.$moment(two.to, DATE_FORMAT.BASE_FORMAT).format(DATE_FORMAT.BASE_FORMAT_REVERSE)
        }
      }
    },

    items () {
      if (this.data && this.data.items && Array.isArray(this.data.items)) {
        return this.data.items
            .filter(item => this.urlFilters.catalog_ids.includes(String(item.catalog_id)))
            .map(item => {
              let diffPrc = this.getDiffPrc(item)
              let newItem = {
                one: item[`${this.activeMetric.id}_one`],
                two: item[`${this.activeMetric.id}_two`],
                diff: item[`${this.activeMetric.id}_diff`],
                diff_prc: diffPrc,
                diff_cg: this.controlGroup
                    ? item[`${this.activeMetric.id}_diff`] - this.controlGroup.diff
                    : 0,
                diff_prc_cg: this.controlGroup ? diffPrc - this.controlGroup.diff_prc : 0
              }

              if (item.name_hash) {
                newItem.catalog_id = item.name_hash
                newItem.url_title = item.name
              } else {
                [
                  'catalog_id',
                  'url_title',
                  'url_name',
                  'level',
                  'listingPage',
                  'type_id',
                  'count_elements',
                  'count_listings',
                  'count_tag_listings',
                  'count_offers',
                  'is_listing'
                ].forEach(key => newItem[key] = item[key])
              }
              return newItem
        })
            .sort((a, b) => {
              return this.urlFilters.order_direction === 'ASC'
              ? a[this.urlFilters.order_by] - b[this.urlFilters.order_by]
              : b[this.urlFilters.order_by] - a[this.urlFilters.order_by]
            })
      } else {
        return []
      }
    }
  },

  methods: {
    getLevel (id) {
      const item = findOptions(String(id), this.segmentTreeCatalog.items)

      return item && item.level || '-'
    },

    setActiveSegment (segment) {
      this.$set(this, 'activeSegment', segment)
    },

    clearActiveSegment () {
      this.$set(this, 'activeSegment', undefined)
    },

    getDiffPrc (item) {
      const diff = this.getValue(item[`${this.activeMetric.id}_diff`])
      const one = this.getValue(item[`${this.activeMetric.id}_one`])
      const two = this.getValue(item[`${this.activeMetric.id}_two`])

      return diff === undefined
          ? undefined
          : diff === 0
              ? 0
              : diff === one
                  ? 100
                  : roundNumber(diff * 100 / two, 2)
    },

    getValue (val) {
      return typeof val === 'string' ? hmsToSeconds(val) : val
    }
  }
}
</script>

<style scoped lang="scss">
.table-cell__period {
  white-space: nowrap;
  color: #455165;
  font-weight: 400;
}
</style>
