var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-page-title',{staticClass:"_border-bottom _mb-0",attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary}}),(_vm.viewPage)?[_c('v-filters',_vm._b({on:{"update-url":_vm.updateUrl,"finish-filters-loading":_vm.finishFiltersLoading,"toggle-chart":function($event){_vm.showChart ^= true}}},'v-filters',{
          urlFilters: _vm.urlFilters,
          showChart: !!_vm.showChart,
          metrics: _vm.metrics,
          devices: _vm.devices,
          searchEngines: _vm.searchEngines,
          dateFilters: _vm.dateFilters,
          filterName: 'catalog_ids',
          regions: _vm.regions,
          isMixedSegments: true
        },false)),_c('div',{staticClass:"dashboard-wrapper _border-none _mt-32"},[(_vm.preloader.table && !_vm.preloader.filters && !_vm.preloader.segments)?_c('v-preloader',{attrs:{"static":"","clear":""}}):(!_vm.selectedItems.length && !_vm.preloader.filters && !_vm.preloader.segments)?_c('v-error',{attrs:{"title":"Сегменты не выбраны","subtitle":"Выберите сегменты для сравнения","show-links":false}}):(!!_vm.selectedItems.length && _vm.showChart)?_c('v-container',{staticClass:"dashboard-block _mb-32 _ha",attrs:{"has-data":!!_vm.selectedItems.length,"title":_vm.activeMetric.name.replace('<br>', '')}},[_c('v-segment-metric-chart',{attrs:{"items":_vm.selectedItems,"metric":_vm.activeMetric,"date-filters":_vm.dateFilters,"date-filters-formatted":_vm.dateFiltersFormatted,"control-group":_vm.controlGroup,"id-key":'catalog_id'}})],1):_vm._e(),_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"total"},[_vm._v("Всего: "),_c('span',[_vm._v(_vm._s(_vm._f("formatCount")(_vm.mixedSegments.total)))])])]},proxy:true}],null,false,1752758821)},'v-container',{
            load: _vm.preloader.table,
            hasData: _vm.mixedSegments.has_data
          },false),[_c('v-comparison-table-mixed-segments',_vm._b({},'v-comparison-table-mixed-segments',{
              data: _vm.mixedSegments,
              dateFilters: _vm.dateFilters,
              activeMetric: _vm.activeMetric,
              urlFilters: _vm.urlFilters,
              controlGroup: _vm.controlGroup,
              segmentFilterName: 'catalog_ids',
              segmentTreeCatalog: _vm.segmentTreeCatalog
            },false))],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }